<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img
          src=""
          width="200px"
        >
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <!-- class="d-flex flex flex-column justify-content-between"
      <div class="">

      </div> -->
      <!-- <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        style="background:#f6f6f6;"
      > -->
      <!-- <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        > -->
      <div
        class="d-flex justify-content-center flex-column auth-bg px-2 p-lg-5 col-lg-4"
        style="background:#f6f6f6"
      >
        <!-- div 1 -->
        <div class="d-flex align-items-center px-xl-2 mx-auto col-sm-8 col-md-6 col-lg-12">
          <div
            class="d-flex flex-column justify-content-center"
            style="width: 100%;"
          >
            <div
              class="row d-flex
                   justify-content-center mb-2"
            >
              <img
                src="@/assets/images/logo/logo-ranhill2.svg"
                alt=""
              >
            </div>
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              {{ $t ("Welcome") }}
            </b-card-title>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- email -->
                <b-form-group
                  :label="$t('Email')"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Email')"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="email"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t ("Password") }}</label>
                    <b-link :to="{ name: 'forgot-password' }">
                      <small>{{ $t ("Forgot Password?") }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Password')"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <!-- <b-form-group>
      <b-form-checkbox
        id="remember-me"
        v-model="status"
        name="checkbox-1"
      >
        {{ $t ("Remember Me") }}
      </b-form-checkbox>
    </b-form-group> -->

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  class="saj-button mt-3"
                  @click="userLogin()"
                >
                  {{ $t ("Sign in") }}
                </b-button>
              </b-form>
            </validation-observer>
            <div
              class="mt-2"
              style="font-style: italic;"
            >
              {{ $t ("For any inquiries or suggestion, please write to:") }}
            </div>
            <div
              style="font-style: italic;"
            >
              <a :href="'mailto:' + emailTo + '?subject=Ranhill [Subsidiary] - Issue/Request' + '&body=' + body"> helpdesk@aerodyne.group </a>
            </div>
          </div>
        </div>

      </div>

      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate"
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardTitle,
  // BFormCheckbox,
  // BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue"
import { required, email } from "@validations"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import axios from "axios"
// import { password } from '@/@core/utils/validations/validations'
// import { Base } from '@/controller/event-controller'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SAJToast from '@/component/saj-toastification.vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: "",
      required,
      email,
      status: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      emailTo: ' helpdesk@aerodyne.group',
      // subject: 'Inquiry/Suggestion',
      body: 'Hello Helpdesk Team,%0D%0A%0D%0AI have a request/ an issue regarding [briefly explain your request or issue]%0D%0A%0D%0A%0D%0A%0D%0A%0DPlease find my details below:%0D%0A%0D%0A\u2022 Full Name: [Your Full Name]%0D\u2022 Employee Number: [Your Employee Number]%0D\u2022 Email Address: [Your Email Address]%0D\u2022 Contact Number: [Your Contact Number]%0D\u2022 Subsidiary: [Your Subsidiary]%0D%0A%0D%0AThank you for your attention to this matter.%0D%0A%0D%0ABest regards,%0D[Your Name]',
      // validation rulesimport store from '@/store/index'
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    imgUrl() {
      return this.sideImg
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions(['assignUserData']),
    ...mapActions(["setAppLanguage"]),
    onSelectionLanguage(lang) {
      this.locale = lang
      this.$i18n.locale = lang

      this.setAppLanguage(lang)
    },
    userLogin() {
      const formData = new URLSearchParams()
      formData.append('email', this.email)
      formData.append('password', this.password)

      axios.post(`${this.$baseUrl}/login`, formData).then(response => {
        if (response.data.success === true){
          // console.log('role', response.data.data.role[0])
          this.assignUserData(response.data.data)
          if (response.data.data.role[0] === 2){
            this.onSelectionLanguage('ma')
          } else {
            this.onSelectionLanguage('en')
          }

          let whichRoute = 'dashboard'
          const isAdmin = response.data.data.role.includes(1)
          if (isAdmin) {
            whichRoute = 'create-company'
          }

          this.$router
            .replace({ name: whichRoute }).then(() => {
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t('You have successfully login')}`,
                    icon: 'UserIcon',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            })
            .catch(() => {
              // console.log("all good: ", e)
            })
        } else {
          // eslint-disable-next-line no-alert
          alert(response.data.data)
        }
      }).catch(() => {
        // console.log(error.response)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: `Incorrect email  or password`,
              icon: 'UserIcon',
              variant: 'primary',
            },
          },
        )
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

// .success-toast{
//   background: hsla(128, 78%, 51%, 0.664);
//     backdrop-filter: blur(20px);
//     position: absolute;
//     padding-top: 6px;
//     padding-bottom: 6px;
//     top: 20px;
// }
</style>
